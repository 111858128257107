<template>
    <div class="article-body">
        <div class="breadcrumb">
            <div class="left-nav">
                <span class="icon" v-html="ARTICLE_ICON.homeIcon"></span>
                <span>首页</span>
                <span>/</span>
                <span>正文</span>
            </div>
            <div class="right-share">
                <span>分享到：</span>
                <span class="icon" v-html="ARTICLE_ICON.weiboIcon"></span>
                <span>/</span>
                <span class="icon" v-html="ARTICLE_ICON.friendCircleIcon"></span>
            </div>
        </div>
        <div class="article-con">
            <mavon-editor
                ref="md"
                v-model="mark_content"
                :externalLink="externalLink"
                :subfield="subfield"
                :code_style="code_style"
                :ishljs="true"
                :boxShadow="false"
                defaultOpen="preview"
                :toolbarsFlag="false"
            />
        </div>
        <div class="article-bottom">
            <div class="article-tip">
                <span><span v-html="ARTICLE_ICON.updateTimeIcon"></span>最后修改： {{ formatTime(articleInfo.updateTime) }}</span>
                <span>© 允许规范转载</span>
            </div>
            <div class="operator-btn">
                <a-button class="btn reward" type="primary">
                    <a-icon :component="rewardIcon" />
                    打赏</a-button
                >
                <a-button @click="activeLike" class="btn like" :class="{Liked: liked}" type="primary">
                    <a-icon :component="likeIcon" />
                    赞 {{ likeNumber }}</a-button
                >
            </div>
            <p>如果觉得我的文章对你有用，请随意赞赏</p>
        </div>
        <div class="next-article">
            <a-button disabled type="primary"> 下一篇 </a-button>
        </div>
        <!-- <article-comment :articleId="articleId" /> -->
    </div>
</template>

<script>
    import {ARTICLE_ICON} from '@/lib/article_const'
    import ArticleComment from './ArticleComment.vue'
    import {formatTime} from '@/utils/dayjs'
    import {mapActions} from 'vuex'
    export default {
        name: 'ArticleBody',
        props: {
            mark_content: {
                type: String,
                default: '',
            },
            articleId: {
                type: String || Number,
                default: '',
            },
            articleInfo: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                ARTICLE_ICON,
                liked: false, // 已赞
                subfield: false,
                code_style: 'solarized-dark',
                likeNumber: '',
                externalLink: {
                    markdown_css: function () {
                        // 这是你的markdown css文件路径
                        return '/markdown/github-markdown.min.css'
                    },
                    hljs_js: function () {
                        // 这是你的hljs文件路径
                        return '/highlightjs/highlight.min.js'
                    },
                    hljs_css: function (css) {
                        // 这是你的代码高亮配色文件路径
                        return '/highlightjs/styles/' + css + '.min.css'
                    },
                    hljs_lang: function (lang) {
                        // 这是你的代码高亮语言解析路径
                        return '/highlightjs/languages/' + lang + '.min.js'
                    },
                    katex_css: function () {
                        // 这是你的katex配色方案路径路径
                        return '/katex/katex.min.css'
                    },
                    katex_js: function () {
                        // 这是你的katex.js路径
                        return '/katex/katex.min.js'
                    },
                },
                rewardIcon: {
                    template: `
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="icon" aria-hidden="true"><path d="M10.084 7.606c3.375-1.65 7.65-1.154 10.493 1.487 3.497 3.25 3.497 8.519 0 11.77-3.498 3.25-9.167 3.25-12.665 0-.897-.834-2.488-2.96-2.488-5.085" stroke="currentColor" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17.392 14.78s1.532-1.318-.053-2.79c-1.585-1.473-3.17-.404-3.719.403-.549.807.495 2.082.93 2.69.434.61 1.364 2.182-.054 3.202-1.417 1.012-3.002.658-4.153-.708-1.15-1.367-.602-3.365 0-3.924M17.338 11.982l1.159-1.076M9.87 18.922l.937-.871" stroke="currentColor" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M.8 1.205s7.15 4.673 8.773 6.182c1.623 1.508 3.231 4.008 1.616 5.509-2.195 2.04-4.054.595-6.737-.75-.884-.447-3.15-1.777-3.15-1.777M10.136.9l1.047 3.188" stroke="currentColor" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
  `,
                },
                likeIcon: {
                    template: `<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="shake-little unlike_svg feather feather-thumbs-up"><path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path></svg>`,
                },
            }
        },
        components: {
            ArticleComment,
        },
        mounted() {},
        beforeUpdate() {
            // this.likeNumber = this.articleInfo.likeNumber
        },
        watch: {
            articleInfo(val) {
                this.likeNumber = this.articleInfo.likeNumber
            },
        },
        methods: {
            ...mapActions({vxLikeArticle: 'article/likeArticle'}),
            async activeLike() {
                this.liked = true
                await this.vxLikeArticle({articleId: this.articleId})
                this.likeNumber = Number(this.likeNumber) + 1
            },
            formatTime(time) {
                return formatTime(time, 'YYYY 年 MM 月 DD 日')
            },
        },
    }
</script>

<style lang="less" scoped>
    .article-body {
        max-width: 840px;
        padding: 20px;
        .breadcrumb {
            display: flex;
            justify-content: space-between;
            padding: 0px 15px;
            margin-bottom: 20px;
            height: 36px;
            line-height: 36px;
            color: #777;
            font-size: 14px;
            background-color: #fff;
            border-radius: 4px;
            .icon {
                vertical-align: -3px;

                svg {
                    width: 14px;
                    height: 14px;
                }
            }
            .mr-5 {
                margin-right: 5px;
            }
            .left-nav,
            .right-share {
                span + span {
                    margin-left: 5px;
                }
            }
        }
        .markdown-body {
            position: static !important;
        }
        .article-bottom {
            padding-top: 20px;
            background-color: #ffffff;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            span::selection,
            p::selection {
                background-color: #a862ea;
            }
            /deep/ .article-tip {
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                color: #9b9b9b;
                padding: 0px 25px;
                svg {
                    height: 12px;
                    width: 12px;
                    vertical-align: -1px;
                    margin-right: 2px;
                }
            }
            /deep/ .operator-btn {
                display: flex;
                justify-content: center;
                padding: 50px 0 30px;
                .btn {
                    width: 78px;
                    height: 30px;
                    border-radius: 50px;
                    color: #fff;
                    font-size: 14px;
                    svg {
                        fill: none;
                    }
                }
                .reward {
                    margin-right: 15px;
                    background-color: #c0a46b;
                    &:hover {
                        background: #b69c66;
                    }
                }
                .like {
                    background-color: #b24950;
                    &:hover {
                        background-color: #9f4147;
                    }
                }
                .Liked {
                    svg {
                        fill: currentColor;
                    }
                }
            }
            p {
                text-align: center;
                color: #999;
                font-size: 13px;
                padding-bottom: 50px;
            }
        }
        .next-article {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            margin: 20px 0;
            button {
                border-radius: 50px;
            }
        }
    }
</style>
