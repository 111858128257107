<template>
    <div class="Article">
        <article-head :articleInfo="articleInfo" />
        <article-body :mark_content="mark_content" :articleId="articleId" :articleInfo="articleInfo" />
    </div>
</template>

<script>
    import ArticleBody from './components/ArticleBody.vue';
    import ArticleHead from './components/ArticleHead.vue';
    import {mapActions} from 'vuex';
    export default {
        components: {ArticleHead, ArticleBody},
        name: 'Article',

        data() {
            return {
                articleId: this.$route.query.articleId,
                mark_content: '',
                articleInfo: {},
            };
        },

        mounted() {
            this.getOneArticle()
            this.lookArticle()
        },

        methods: {

            ...mapActions({vxGetOneArticle: 'root/getOneArticle', vxLookArticle: 'article/lookArticle'}),
            async getOneArticle() {
                const {data} = await this.vxGetOneArticle({articleId: this.articleId})
                console.log(data);
                this.articleInfo = data
                this.mark_content = data.mark_content
            },
            async lookArticle() {
                await this.vxLookArticle({articleId: this.articleId})
            }
        },
    };
</script>

<style lang="less" scoped>
    .Article {
        padding-bottom: 50px;
    }
</style>