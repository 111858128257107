<template>
    <div class="article-head">
        <h1>{{ articleInfo.articleTitle }}</h1>
        <ul class="article-meta">
            <li>
                <span class="icon" v-html="ARTICLE_ICON.metaAuthor"></span>
                <span>a-puhaha</span>
            </li>
            <li>
                <span class="icon" v-html="ARTICLE_ICON.metaDate"></span>
                <span>{{ formatTime(articleInfo.articleDate) }}</span>
            </li>
            <li>
                <span class="icon" v-html="ARTICLE_ICON.metaViews"></span>
                <span>{{ articleInfo.viewNumber }} 次浏览</span>
            </li>
            <li>
                <span class="icon" v-html="ARTICLE_ICON.metaComments"></span>
                <span>4 条评论</span>
            </li>
            <li>
                <span class="icon" v-html="ARTICLE_ICON.metaWord"></span>
                <span>{{ articleInfo.mark_content.length }}字数</span>
            </li>
            <li>
                <span class="icon" v-html="ARTICLE_ICON.metaCategory"></span>
                <span>{{ ACTIVITY_CLASSIFY_MAP[articleInfo.articleClassify] }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
    import {ARTICLE_ICON, ACTIVITY_CLASSIFY_MAP} from '@/lib/article_const'
    import {formatTime} from '@/utils/dayjs'
    export default {
        name: 'Articlehead',
        props: {
            articleInfo: {
                type: Object,
                default: () => { }
            }
        },
        data() {
            return {
                ARTICLE_ICON,
                ACTIVITY_CLASSIFY_MAP
            };
        },

        mounted() {

        },

        methods: {
            formatTime(time) {
                return formatTime(time, 'YYYY 年 MM 月 DD 日')
            }
        },
    };
</script>

<style lang="less" scoped>
    .article-head {
        padding: 20px;
        background-color: #f9f9f9;
        h1 {
            color: #000;
            font-size: 30px;
            font-weight: 300;
        }
        .article-meta {
            display: flex;
            color: #a0a0a0;
            font-size: 13px;
            margin-top: 10px;
            li {
                padding: 5px;
                .icon {
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                    vertical-align: -2px;
                }
            }
        }
    }
</style>