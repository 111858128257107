<template>
    <div class="article-comment">
        <div class="post-comment-con">
            <p class="title">发表评论</p>
            <textarea ref="textarea" v-model="commentContent" name="" id="" placeholder="说点什么吧……"></textarea>
            <div class="operator-con">
                <Poptip ref="poptip" placement="bottom" width="320">
                    <span class="emoji-span" @click="$refs.textarea.focus()"><a-icon class="smile" type="smile" />表情</span>
                    <div class="api" slot="content">
                        <ul class="emoji-list">
                            <li @click="addEmoji(item)" v-for="(item, index) in EMOJI" :key="index">{{ item }}</li>
                        </ul>
                    </div>
                </Poptip>

                <a-button @click="postComment" class="post-comment-btn" type="primary">发表评论</a-button>
            </div>
        </div>

        <p class="comment-num">{{ commentList.length }}条评论 <span v-html="ARTICLE_ICON.hotIcon"></span></p>
        <div class="CommentItem" v-for="item in commentList" :key="item._id">
            <CommentItem :commentItem="item" />
        </div>
    </div>
</template>

<script>
    import CommentItem from './CommentItem.vue'
    import {ARTICLE_ICON} from '@/lib/article_const'
    import {EMOJI} from '@/lib/emoji_const'
    import {mapState, mapActions} from 'vuex'
    export default {
        components: {CommentItem},
        name: 'ArticleComment',
        props: {
            articleId: {
                type: String || Number,
                default: '',
            },
        },
        data() {
            return {
                ARTICLE_ICON,
                EMOJI,
                commentContent: '',
                commentList: [],
            }
        },
        computed: {
            ...mapState({
                vxLoginStatus: (state) => state.user.loginStatus,
                vxUserInfo: (state) => state.user.userInfo,
            }),
        },

        methods: {
            ...mapActions({vxPostComments: 'article/postComments', vxGetComments: 'article/getComments'}),
            addEmoji(emoji) {
                this.$refs.textarea.focus()
                let index = this.$refs.textarea.selectionEnd
                console.log(index)
                this.commentContent = this.commentContent.slice(0, index) + emoji + this.commentContent.slice(index)
                // console.dir(this.$refs.textarea);
                this.$refs.poptip.visible = false
            },
            async postComment() {
                if (!this.vxLoginStatus) {
                    return this.$notification.open({
                        message: 'User not logged in',
                        description: '请点击网页右上角小钥匙登录后，再发表评论',
                        icon: <a-icon type="smile" style="color: #108ee9" />,
                    })
                }
                if (!this.commentContent.trim()) {
                    return this.$message.error('评论内容不能为空')
                }
                try {
                    const data = await this.vxPostComments({
                        replay_user_id: this.vxUserInfo.id,
                        name: this.vxUserInfo.userName,
                        avater: this.vxUserInfo.userIcon,
                        commentContent: this.commentContent,
                        articleId: this.articleId,
                    })
                    console.log(data)
                    if (data.code) {
                        this.$message.success(data.msg)
                        this.commentContent = ''
                        this.getComments()
                    } else {
                        this.$message.error(data.msg)
                    }
                } catch (error) {
                    this.$message.error('评论失败，请稍后再试')
                }
            },

            async getComments() {
                console.log(this.articleId)
                const data = await this.vxGetComments({articleId: this.articleId})
                // console.log(data);
                this.commentList = data
            },
        },
        mounted() {
            this.getComments()
        },
    }
</script>

<style lang="less" scoped>
    .article-comment {
        background-color: #fff;
        padding: 32px;
        border-radius: 4px;
        .post-comment-con {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            .title {
                margin-bottom: 15px;
                color: #252933;
                font-size: 18px;
            }
            textarea {
                height: 114px;
                border: 1px solid #1e80ff;
                border-radius: 4px;
                padding: 6px 12px;
                background: url('../../../assets/images/winter-games.gif') no-repeat right/contain;
                transition: all 0.5s;
                &:focus {
                    background: url('../../../assets/images/winter-games.gif') no-repeat right/contain;
                    background-position-y: 200px;
                }
            }
            .emoji-span {
                /*  width: 62px;
                                                                                height: 24px; */
                font-size: 14px;
                text-align: center;
                padding: 3px 6px;
                line-height: 24px;
                border-radius: 4px;
                color: #252933;
                background-color: #ffdf8f;
                cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
                .smile {
                    margin-right: 2px;
                }
            }
            .post-comment-btn {
                width: 124px;
                background: #448bff linear-gradient(45deg, #448bff, #44e9ff);
                border-radius: 50px;
            }
            .operator-con {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 10px 0;
                user-select: none;
                .emoji-list {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        height: 25px;
                        width: 25px;
                        margin: 8px;
                        font-size: 25px;
                        color: #0095f6;
                        cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
                    }
                }
            }
        }

        /deep/ .comment-num {
            font-size: 18px;
            color: #252933;
            margin-bottom: 25px;
            span {
                margin-left: 4px;
            }
            svg {
                vertical-align: -2px;
            }
        }
        .CommentItem {
            margin-bottom: 32px;
        }
    }
</style>
